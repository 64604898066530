import { FC, useEffect } from 'react'
import { useMyGroups } from 'amity/lib'
import { CardSection, GroupsCarousel, GroupsList, withErrorBoundaryAndLogging } from 'ui/components/content'
import { MainPageComponentProps } from '../ComponentProps'
import { useSlidesToShow } from '../useSlidesToShow'

const MAX_GROUPS_IN_THE_COMPONENT = 4

const GroupsImplementation: FC<MainPageComponentProps> = ({ isLoading, onReady }) => {
  const slidesToShow = useSlidesToShow()
  const { groups, isLoading: isGroupsLoading } = useMyGroups(MAX_GROUPS_IN_THE_COMPONENT)

  useEffect(() => {
    if (!isGroupsLoading) {
      onReady?.()
    }
  }, [isGroupsLoading])

  return (
    <CardSection
      title="My groups"
      href={groups.length > 0 ? '/groups' : undefined}
      isLoading={isLoading}
    >
      <GroupsList
        groups={groups}
        isLoading={isLoading || isGroupsLoading}
        className="hidden xl:flex"
      />
      <GroupsCarousel
        slidesToShow={slidesToShow}
        groups={groups}
        isLoading={isLoading || isGroupsLoading}
        className="xl:hidden"
      />
    </CardSection>
  )
}

export const Groups = withErrorBoundaryAndLogging(GroupsImplementation)
